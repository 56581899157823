import { Component, OnInit} from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-success-landing',
    templateUrl: './success-landing.component.html',
    styleUrls: ['./success-landing.component.scss']
})
export class SuccessLandingComponent implements OnInit{

    rm = environment.partner.rm;
    rh = environment.partner.rh;

    constructor() {}

    ngOnInit() {}

    goToPartner(partner: string) {
      window.location.assign(environment.partner[partner]);
    }
}
