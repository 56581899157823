import {Injectable} from '@angular/core';

declare let _satellite;

@Injectable()
export class AnalyticsService {

    // standard satellite tracking passing in name of event

    public track(name: string) {
        if (typeof _satellite === 'object') {
            _satellite.track(name);
        }
    }

    // decode token when request is made from ping fed to send ra id to bi

    public decodeToken(token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        this.storeId(payload.rocketAccountId);
    };

    // send token in standard format ra id to bi

    public storeId(id) {
        document.cookie = 'raid=' + id;
    };

}

