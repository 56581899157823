import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class ForgotUsernameService {

      private url = `${environment.apiConsumerBase}/account/forgot-username/`;
    private options = {
        headers: new HttpHeaders({
           'Content-Type': environment.headers.standard,
            'Accept': environment.headers.standard
        })
    }

    constructor(private http: HttpClient) { }

    forgotUsernameSubmit(email: string) {
        const request = JSON.stringify({
            email: email.trim()
        });

        return this.http.post(this.url, request, this.options);
    }
}
