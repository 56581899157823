import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ForgotUsernameService } from 'app/services/logical/forgot-username.service';
import { AppHookService } from 'app/services/app-hook.service';

@Component({
    selector: 'app-forgot-username',
    templateUrl: './forgot-username.component.html',
    styleUrls: ['./forgot-username.component.scss'],
    providers: [ForgotUsernameService]
})
export class ForgotUsernameComponent implements OnInit {

    emailField: string;
    status: boolean;

    form = this.fb.group({
        email: ['', [Validators.required, Validators.pattern(/^[\w$\-_\.+!\*'()]+@([\w\-\_]+\.)+[A-Za-z]+/)]]
    });

    alertType: string;

    constructor(
        private usernameService: ForgotUsernameService,
        private hooks: AppHookService,
        private fb: FormBuilder
    ) { }

    ngOnInit() { }

    onSubmit() {
        if (this.form.invalid) { 
            Object.keys(this.form.controls).forEach( control => {
                this.form.get(control).markAsTouched();
            })
            return; 
        }

        let payload = {
            username: this.emailField
        };
        this.status = true;
        this.usernameService.forgotUsernameSubmit(this.email.value)
            .subscribe(
                data => {
                    this.alertType = 'success';
                    this.status = false;
                    this.hooks.messageApp(true, 'forgotUsername', payload);
                },
                error => {
                    this.alertType = 'error';
                    this.status = false;
                    this.hooks.messageApp(false, 'forgotUsername', payload, error);
                }
            );
    }

    trimWhitespace() {
        this.emailField = this.emailField.trim();
    }

    dismissAlert() {
        this.alertType = null;
    }

    get email() { return this.form.get('email') }

    get isWebView() {
        return this.hooks.isWebView;
    }
}
