import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePasswordService } from 'app/services/logical/change-password.service';
import { AppHookService } from 'app/services/app-hook.service';
import { sessionChecker, matchOtherValidator } from 'app/consumer/util/validators';
import { regex } from 'app/consumer/util/regex';
import { readCookie } from 'app/consumer/util/cookie';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [ChangePasswordService]
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup = this.fb.group({
    currentPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.pattern(regex.password), Validators.minLength(5), Validators.maxLength(256), matchOtherValidator('currentPassword', 'equal')]],
    confirmPassword: ['', [Validators.required, Validators.pattern(regex.password), Validators.minLength(5), Validators.maxLength(256), matchOtherValidator('newPassword', 'not')]]
  });
  status: boolean;
  invalid: boolean;
  passwordModal: boolean = false;
  passwordFocused: boolean = true;
  token = readCookie('rocket-auth');
  errorCode: number;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private _location: Location,
    private _router: Router,
    private _changePassword: ChangePasswordService,
    private hooks: AppHookService ) { }

  ngOnInit() {
    if (!this.token) {
      this._router.navigateByUrl('sign-in');
    }
  }

  get currentPassword() { return this.passwordForm.get('currentPassword') }
  get newPassword() { return this.passwordForm.get('newPassword') }
  get confirmPassword() { return this.passwordForm.get('confirmPassword') }

  openModal() {
    this.passwordModal = true;
  }

  closeModal() {
    this.passwordModal = false;
    this._router.navigateByUrl('sign-in');
  }

  onSubmit() {
    if (this.passwordForm.invalid) {
        Object.keys(this.passwordForm.controls).forEach( control => {
            this.passwordForm.get(control).markAsTouched();
        })
        return;
    }

    this.status = true;
    this._changePassword.changePasswordSubmit(this.currentPassword.value, this.newPassword.value, this.token).subscribe(
      data => {},
      error => {
        this.status = false;
        sessionChecker(error);
        for (const res of error.error) {
          this.errorCode = res.code;
          this.hooks.messageApp(false, 'changePassword', {}, error);
        }
      },
      () => {
        this.passwordModal = true;
        this.status = false;
        sessionStorage.setItem('change-password', 'true');
        this.hooks.messageApp(true, 'changePassword', {});
      }
    );
  }

  goBack() {
    this._location.back();
  }

  dismissAlert() {
    this.errorCode = null;
  }

  get isWebView() {
    return this.hooks.isWebView;
  }

}

