import { Component, OnInit } from '@angular/core';

declare let lpTag: { newPage: Function };

@Component({
    selector: 'app-talk-to-us',
    templateUrl: './talk-to-us.component.html',
    styleUrls: ['./talk-to-us.component.scss']
})
export class TalkToUsComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    // lpTag.section = ['ra-talk-to-us'];
    this.lpTag().newPage(window.location.href, {
      section: ['ra-talk-to-us'],
      sdes: [],
      taglets: {
        rendererStub: {}
      }
    });
  }

  lpTag(): any {
    return lpTag;
  }

  goBack() {
    window.history.back();
  }
}
