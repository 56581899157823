import { Injectable } from '@angular/core';
import { RedirectService } from 'app/services/generic/redirect.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MetricsService } from 'app/services/metrics.service';
import { environment } from 'environments/environment';

@Injectable()
export class HttpService {

    private headers = new HttpHeaders({
        'Content-Type': environment.headers.version,
        'Accept': environment.headers.version
    });

    private stubHeaders = 	new HttpHeaders({
      'Content-Type': environment.headers.version,
      'Accept': environment.headers.standard
    });

    constructor(
        private _redirectService: RedirectService,
        private metrics: MetricsService,
        private http: HttpClient) {
    }

    set redirect(url) {
        this._redirectService.redirect = url;
    }

    get redirect() {
        return this._redirectService.redirect;
    }

    postCreate(newUser) {
        let payload = Object.assign({}, newUser, this.metrics.metrics);

        if (this.redirect !== '/#/success-landing') {
            payload['redirect'] = this.redirect;
        }

        return this.http.post(environment.apiConsumerBase + '/account/', JSON.stringify(payload), { headers: this.headers });
    }

    postCreateStub(newUser) {
        let payload = Object.assign({}, newUser, this.metrics.metrics);
        return this.http.post(environment.apiConsumerBase + '/account/stub/', JSON.stringify(payload),
          { headers: this.stubHeaders});
    }
}
