import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileResolver } from './profile.resolver';

import { PageNotFoundComponent } from 'app/consumer/components/page-not-found/page-not-found.component';
import { SignInComponent } from 'app/consumer/components/sign-in/sign-in.component';
import { SignedInVerificationComponent } from 'app/consumer/components/signed-in-verification/signed-in-verification.component';
import { CreateAccountFormComponent } from 'app/consumer/components/create-account-form/create-account-form.component';
import { StubCreateAccountFormComponent } from 'app/consumer/components/stub-create-account-form/stub-create-account-form.component';
import { ResetPasswordComponent } from 'app/consumer/components/reset-password/reset-password.component';
import { NewPasswordComponent } from 'app/consumer/components/new-password/new-password.component';
import { SuccessLandingComponent } from 'app/consumer/components/success-landing/success-landing.component';
import { EmailInstructionsComponent } from 'app/consumer/components/email-instructions/email-instructions.component';
import { EmailVerifyComponent } from 'app/consumer/components/email-verify/email-verify.component';
import { ForgotUsernameComponent } from 'app/consumer/components/forgot-username/forgot-username.component';
import { ChangeUsernameComponent } from 'app/consumer/components/change-username/change-username.component';
import { ChangePasswordComponent } from 'app/consumer/components/change-password/change-password.component';
import { TalkToUsComponent } from 'app/consumer/components/talk-to-us/talk-to-us.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { KillSessionCheck } from 'app/consumer/util/kill-session-check';
import { ValidSessionCheck } from 'app/consumer/util/valid-session-check';
import { MetricsResolver } from 'app/consumer/routers/metrics.resolver';

const appRoutes: Routes = [
    { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
    { path: 'success-landing', /*canActivate: [ValidSessionCheck],*/ component: SuccessLandingComponent },
    { path: 'sign-in', resolve: [ KillSessionCheck ], component: SignInComponent },
    { path: 'create-account', resolve: [ MetricsResolver, KillSessionCheck ], component: CreateAccountFormComponent },
    { path: 'talk-to-us', component: TalkToUsComponent },
    { path: 'stub-create-account', resolve: [ MetricsResolver, KillSessionCheck ], component: StubCreateAccountFormComponent },
    { path: 'sign-in/verify', component: SignedInVerificationComponent },
    { path: 'password-reset', component: ResetPasswordComponent },
    { path: 'password-reset/instructions', component: EmailInstructionsComponent },
    { path: 'password-reset/verify/:token', component: NewPasswordComponent },
    { path: 'change-password', component: ChangePasswordComponent },
    { path: 'email/instructions', component: EmailInstructionsComponent },
    { path: 'email/verify/:token/:redirect', component: EmailVerifyComponent },
    { path: 'email/verify/:token', component: EmailVerifyComponent },
    { path: 'change-username/claim/:username-token', component: EmailVerifyComponent },
    { path: 'forgot-username', component: ForgotUsernameComponent },
    { path: 'change-password', component: ChangePasswordComponent },
    { path: 'change-username', component: ChangeUsernameComponent },
    { path: 'change-username/instructions', component: EmailInstructionsComponent },
    { path: 'profile', component: ProfileComponent, resolve: { profile: ProfileResolver } },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
    exports: [RouterModule],
    providers: [
        ProfileResolver,
        MetricsResolver,
        KillSessionCheck,
        ValidSessionCheck
    ]
})
export class AppRoutingModule {}
