import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosComponent implements AfterViewInit {

  @Output() closed = new EventEmitter<any>();
  @ViewChild('notice') notice: ElementRef;

  constructor() { }

  ngAfterViewInit() {
  }

  close() {
    this.closed.emit(true);
  }

  goToNotice() {
    this.notice.nativeElement.scrollIntoView();
  }

}
