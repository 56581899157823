import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as states from 'us-states';
import { ProfileService } from 'app/services/profile.service';
import { AppHookService } from 'app/services/app-hook.service';
import { regex } from 'app/consumer/util/regex';
import { realDate } from 'app/consumer/util/validators';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  private validators = {
    firstName: [Validators.required, Validators.pattern(regex.name), Validators.minLength(2), Validators.maxLength(50)],
    lastName: [Validators.required, Validators.pattern(regex.name), Validators.minLength(2), Validators.maxLength(50)],
    dateOfBirth: [Validators.required, Validators.pattern(regex.date), realDate()],
    streetAddress: [Validators.required, Validators.pattern(regex.alphanumeric), Validators.maxLength(256)],
    additionalAddress: [Validators.pattern(regex.alphanumeric), Validators.maxLength(35)],
    city: [Validators.required, Validators.pattern(regex.alphanumeric), Validators.maxLength(80)],
    state: [Validators.required],
    zipCode: [Validators.required, Validators.pattern(regex.numeric), Validators.maxLength(5)]
  };

  form = this.fb.group({
    account: this.fb.group({
      firstName: ['', this.validators.firstName],
      lastName: ['', this.validators.lastName],
      preferredName: ['', [Validators.pattern(regex.name), Validators.minLength(2), Validators.maxLength(50)]]
    }),
    addresses: this.fb.array([]),
    dateOfBirth: ['', this.validators.dateOfBirth],
    family: this.fb.group({
      familySize: ['']
    }),
    finance: this.fb.group({
      homeOwnership: ['']
    }),
  });

  states = [];
  sizes = ['1', '2', '3', '4', '5', '6+'];
  fieldValues;

  alert: object | null;

  returnLink: string;

  @ViewChild('stopPoint') scrollTarget: ElementRef;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private hooks: AppHookService) { }

  ngOnInit() {
    for (let i in states) {
      this.states.push({code: i, name: states[i]});
    }

    this.profileService.lookup().subscribe(
      (response) => this.fieldValues = response
    );

    this.route.data.subscribe((data: { profile: object}) => {
      if (data.profile['addresses'] && data.profile['addresses'].length) {
        for(let address of data.profile['addresses']) {
          this.addAddress();
        }
      } else {
        this.addAddress();
      }
      this.loadProfile(data.profile, this.form);
    })

    this.returnLink = this.route.snapshot.queryParams['return'] || environment.apiConsumerForward;
  }

  // cycle through the data, make that form field required
  loadProfile(data: any, form: AbstractControl) {
    for(let key of Object.keys(data)) {
      if (form.get(key) instanceof FormControl) {
        switch (key) {
          // preferredName and additionalAddress are never required
          case 'preferredName':
          case 'additionalAddress':
            form.get(key).patchValue(data[key]);
            break;
          // form field format and db format are different
          // switch from YYYY-MM-DD to MM-DD-YYYY
          case 'dateOfBirth':
            let reverse = /(\d{4})\-(\d{2})\-(\d{2})/;
            let temp = data[key].replace(reverse, '$2-$3-$1');
            data[key] = temp;
          default:
            let validators = this.validators[key] ? [...this.validators[key], Validators.required] : [Validators.required];
            form.get(key).setValidators(validators);
            form.get(key).patchValue(data[key]);
            break;
        }
      }

      else if (form.get(key) instanceof FormGroup) {
        this.loadProfile(data[key], form.get(key));
      }

      else if (data[key].length && form.get(key) instanceof FormArray) {
        let index = 0;
        let array = form.get(key) as FormArray;
        for(let control of array.controls) {
          this.loadProfile(data[key][index], control);
          index++;
        }
      }

    }
  }

  saveProfile() {
    if (this.form.invalid) { 
        Object.keys(this.form.controls).forEach( control => {
            this.form.get(control).markAsTouched();
        })
        return; 
    }

    this.profileService.updateProfile(JSON.stringify(this.form.value)).subscribe(
      (data) => {
        if (this.hooks.isWebView) {
          this.hooks.messageApp(true, 'profile', this.form.value);
        } else {
          this.alert = { type: 'success', id: 'success' }
          window.location.reload();
        }
      },
      (errorResponse) => {
        for(let error of errorResponse.error) {
          this.alert = { type: 'error', id: error.code }
        }
        this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
        this.hooks.messageApp(false, 'profile', this.form.value, errorResponse.error);
      }
    );
  }

  makeAddress(): FormGroup {
    return this.fb.group({
      id: [''],
      streetAddress: ['', this.validators.streetAddress],
      additionalAddress: ['', [Validators.pattern(regex.alphanumeric), Validators.maxLength(35)]],
      city: ['', this.validators.city],
      state: ['', this.validators.state],
      zipCode: ['', this.validators.zipCode],
      operation: ['']
    })
  }

  addAddress() {
    let array = <FormArray>this.form.get('addresses');
    array.push(this.makeAddress());
  }

  removeAddress(index) {
    if (this.addresses.controls[index].get('id').value) {
      this.addresses.at(index).get('operation').patchValue('delete');
      let address = <FormGroup>this.addresses.at(index);
      for(let control of Object.values(address.controls)) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    } else {
      this.addresses.removeAt(index);
    }
  }

  changeValue(control, value) {
    this[control].patchValue(value);
  }

  dismissAlert() {
    this.alert = null;
  }

  get firstName() { return this.form.get('account').get('firstName') }
  get lastName() { return this.form.get('account').get('lastName') }
  get preferredName() { return this.form.get('account').get('preferredName') }
  get addresses(): FormArray { return <FormArray>this.form.get('addresses') }
  get dateOfBirth() { return this.form.get('dateOfBirth') }
  get familySize() { return this.form.get('family').get('familySize') }
  get homeOwnership() { return this.form.get('finance').get('homeOwnership') }

  get activeAddressCount(): number {
    let array = <FormArray>this.form.get('addresses');
    return array.controls.filter(
      (group) => { return group.get('operation').value !== 'delete' }
    ).length;
  }

  get isWebView() {
    return this.hooks.isWebView;
  }
}
