import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { readCookie } from 'app/consumer/util/cookie';
import { environment } from 'environments/environment';

@Injectable()
export class MergeService {

  constructor(
    private http: HttpClient
  ) { }

  getToken(): Observable<any> {
    let data = `client_id=${ environment.federate.id }&grant_type=client_credentials&client_secret=${ environment.federate.secret }`;
    let url = `${ environment.federateBase }as/token.oauth2`;
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    return this.http.post(url, data, { headers: headers });
  }

  async checkCredentials(creds: object): Promise<any> {
    let url = `${ environment.apiConsumerBase }/merge/checkCredentials`

    let result;
    this.getToken().subscribe(
      (response) => {
        let token = response['access_token'];
        let headers = {
          'Authorization': `Bearer ${ token }`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
        result = this.http.post(url, creds, { headers: headers });
      }
    );

    return result;
  }

  selectCredentials(data: object): Observable<any> {
    let url = `${ environment.apiConsumerBase }/merge/updateCredentials`;

    let headers = {
      'Authorization': `Bearer ${ readCookie('rocket-auth') }`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    return this.http.post(url, data, { headers: headers });
  }

}
