import { Injectable } from '@angular/core';

@Injectable()
export class MetricsService {

  metrics: {} | { metricIdentifiers: string } = {};

  constructor() { }

}
