import {Component, OnInit} from '@angular/core';
import {PostTokenService} from 'app/services/logical/post-token.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AnalyticsService} from 'app/services/generic/analytics.service';
import { HttpService } from 'app/services/logical/create-account.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss'],
  providers: [PostTokenService]
})
export class EmailVerifyComponent implements OnInit {

  postData: string;
  isValid = true;
  isComplete = false;
  timeValid = true;
  verifyType: string;
  redirect: string;
  timestamp: any;
  emailVerified = false;
  genericError = false;

  constructor(private postToken: PostTokenService,
              private route: ActivatedRoute,
              private analytics: AnalyticsService,
              private httpService: HttpService,
              private router: Router) {
  }

  ngOnInit() {
    let token;

    if (this.route.snapshot.paramMap.get('token')) {
      token = this.route.snapshot.paramMap.get('token');
      this.verifyType = 'createAccount';
    } else {
      token = this.route.snapshot.paramMap.get('username-token');
      this.verifyType = 'usernameUpdate';
    }

    const hours = 72;
    const ms = hours * (60000 * 60);
    const today = Date.now();

    if (this.route.snapshot.queryParams['timestamp']) {
      this.timestamp = this.route.snapshot.queryParams['timestamp'];

      if ((today - this.timestamp) <= ms) {
        this.postVerification(token);
      } else {
        this.timeValid = false;
        this.isValid = false;
      }
    } else {
      this.postVerification(token);
    }

    if (this.route.snapshot.queryParams['redirect']) {
      this.httpService.redirect = atob(this.route.snapshot.queryParams['redirect']);
    }
  }

  postVerification(token) {
    this.postToken.postTokenCreate(token, this.verifyType)
      .subscribe(
        data => this.postData = JSON.stringify(data),
        error => {
          for (const res of error.error) {
            res.code === 1 ?
              this.emailVerified = true :
              this.genericError = true;
          }
          this.isValid = false;
        },
        () => {
          this.isComplete = true;
          sessionStorage.setItem('email-verified', 'true');
          this.analytics.track('email_verify');
        }
      );
  }

  goToRedirect() {
    this.httpService.redirect ?
      window.location.assign(this.httpService.redirect):
      this.router.navigate(['sign-in']);
  }
}
