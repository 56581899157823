import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'indicator',
  template: '<div class="indicator" [class.active]="isActive" [class.complete]="isComplete"></div>',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressIndicatorComponent {
  @Input() isActive: boolean;
  @Input() isComplete: boolean;
}

@Component({
  selector: 'step',
  template: '<div class="step"><ng-content></ng-content></div>',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressStepComponent {}

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  private steps: Array<any>;
  private activeIndex: number;
  useState: boolean = false;

  @Input('steps')
  set stepCount(steps: number) { this.steps = new Array(steps).fill('1fr') }

  @Input()
  set active(step: number) { this.activeIndex = step - 1 }

  @Input() state: Array<[boolean, boolean]>;

  constructor() { }

  ngOnInit() {
    if (this.state != null && this.state.length == this.steps.length) {
      this.useState = true;
    }
  }

  get styles() {
    return { 'grid-template-columns': this.steps.join(' ') }
  }

  get barStyles() {
    let gap = 90;
    let gaps = `${gap * this.steps.length - 1}px`
    return {
      'left': `calc(((100% / ${ this.steps.length }) - ${ gap / 2 }px) / 2)`,
      'width': `calc(100% - (100% / ${ this.steps.length }) + ${ gap / 2 }px)`
    }
  }

}
