import { Component, OnInit } from '@angular/core';
import { HttpService } from 'app/services/logical/create-account.service';
import { JamesService } from 'app/services/logical/post-create-temp.service';
import { AppHookService } from 'app/services/app-hook.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { LoginService } from 'app/services/generic/login.service';
import { environment } from 'environments/environment';
import { oneName, matchOtherValidator } from 'app/consumer/util/validators';
import { writeCookie } from 'app/consumer/util/cookie';
import { regex } from 'app/consumer/util/regex';

@Component({
    selector: 'app-create-account-form',
    templateUrl: './create-account-form.component.html',
    styleUrls: ['./create-account-form.component.css'],
    providers: [HttpService, JamesService, LoginService]
})
export class CreateAccountFormComponent implements OnInit {

    presets: any = {
        firstName: '',
        lastName: '',
        username: ''
    }; // holder for pre-populated form values

    cookieOptions = {
        domain: environment.domain,
        path: '/'
    };

    postData: any;
    status: boolean;
    token: string;
    type = 'password';
    confirmType = 'password';
    emailCharacterCheck: boolean;
    mobileOS: string;
    passwordFocused: boolean = false;

    form = this.fb.group({
        firstName: ['', [Validators.minLength(2), Validators.required, oneName(), Validators.pattern(/^[a-zA-Z`'\-. ]*$/)]],
        lastName: ['', [Validators.minLength(2), Validators.required, oneName(), Validators.pattern(/^[a-zA-Z`'\-. ]*$/)]],
        username: ['', [Validators.required, Validators.pattern(regex.email), Validators.maxLength(128)]],
        password: ['', [Validators.required, Validators.pattern(/^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/)]],
        confirmPassword: ['', [Validators.required, Validators.pattern(/^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/), matchOtherValidator('password', 'not')]]
    });
    errorCode: number;

    constructor(
        private _httpService: HttpService,
        private _router: Router,
        private route: ActivatedRoute,
        private _analytics: AnalyticsService,
        private _loginService: LoginService,
        private _jamesService: JamesService,
        private hooks: AppHookService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams['ragn']) {
            this.presets['firstName'] = atob(this.route.snapshot.queryParams['ragn']);
        }

        if (this.route.snapshot.queryParams['rasn']) {
            this.presets['lastName'] = atob(this.route.snapshot.queryParams['rasn']);
        }

        if (this.route.snapshot.queryParams['race']) {
            this.presets['username'] = atob(this.route.snapshot.queryParams['race']);
        }

        this.form.patchValue(this.presets);

        this.mobileOS = this.route.snapshot.queryParams['app'];

        if (this.route.snapshot.queryParams['redirect']) {
            this._loginService.redirect = this.route.snapshot.queryParams['redirect'];
            sessionStorage.setItem('redirect', this._loginService.redirect);
        }
    }

    onSubmit() {
        if (this.form.invalid) { 
            Object.keys(this.form.controls).forEach( control => {
                this.form.get(control).markAsTouched();
            })
            return; 
        }

        this.status = true;
        let payload = Object.assign({}, this.form.value);
        delete payload.password;
        delete payload.confirmPassword;

        if (this.route.snapshot.queryParams['short'] === 'true') {
            this._jamesService.postCreateJames(this.form.value).subscribe(
                data => {
                    this.postData = data;
                    this.hooks.messageApp(true, 'createAccount', payload);
                },
                error => {
                    for (const res of error.error) {
                        this.errorCode = res.code;
                    }

                    this.status = false;
                    this.hooks.messageApp(false, 'createAccount', payload, error.error[0]);
                },
                () => {
                    this._loginService.login(this.username.value, this.password.value).subscribe(
                        response => {
                            this.token = response.access_token;
                        },
                        error => {
                            this.hooks.messageApp(false, 'createAccount', payload, error);
                        },
                        () => {
                            let options = Object.assign({}, this.cookieOptions, { expires: this.getCurrentTime() });
                            writeCookie('rocket-auth', this.token, options);
                            this._analytics.storeId(this.postData.accountId);

                            this._loginService.redirect
                                ? window.location.href = this._loginService.redirect
                                : this._router.navigateByUrl('success-landing');
                        }
                    );
                }
            );
        } else {
            this._httpService.postCreate(this.form.value).subscribe(
                data => {
                    this.postData = data;
                    this.hooks.messageApp(true, 'createAccount', payload);
                },
                error => {
                    for (const res of error.error) {
                        this.errorCode = res.code;
                    }

                    this.status = false;
                    this.hooks.messageApp(false, 'createAccount', payload, error.error[0]);
                },
                () => {
                    this._analytics.storeId(this.postData.accountId);
                    this._router.navigateByUrl('email/instructions?user=' + this.username.value);
                }
            );
        }
    }

    handleCharacters(event) {
        if (!/^[a-zA-Z0-9$!*'`()+@_\-\.]*$/gi.test(event.target.value)) {
            this.emailCharacterCheck = true;
        } else {
            this.emailCharacterCheck = false;
        }
    }

    getCurrentTime() {
        const now = new Date();
        let time = now.getTime();
        time += 1320 * 1000;
        now.setTime(time);

        return now.toUTCString();
    }

    mask(type) {
        this.type = type;
    }

    confirmMask(type) {
        this.confirmType = type;
    }

    trimWhitespace(attr) {
        if (this.form.get(attr)) {
            this.form.get(attr).setValue(this.form.get(attr).value.trim());
        }
    }

    showRequirements() {
        this.passwordFocused = true;
    }

    hideRequirements() {
        this.passwordFocused = false;
    }

    dismissAlert() {
        this.errorCode = null;
    }

    get firstName() {
        return this.form.get('firstName');
    }

    get lastName() {
        return this.form.get('lastName');
    }

    get username() {
        return this.form.get('username');
    }

    get password() {
        return this.form.get('password');
    }

    get confirmPassword() {
        return this.form.get('confirmPassword');
    }

    get isWebView() {
        return this.hooks.isWebView;
    }
}
