import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class RedirectService {

    constructor() { }

    private _redirect = sessionStorage.getItem('redirect');

    private sanitize(url): string {
        let safe = false;
        for(let domain of environment.whitelist){
            if(url.indexOf(domain) >= 0) {
                safe = true;
            }
        }
        return safe ? url : '/#/success-landing';
    }

    set redirect(url) {
        sessionStorage.setItem('redirect', this.sanitize(url));
        this._redirect = this.sanitize(url);
    }

    get redirect() {
      return this._redirect;
    }
}
