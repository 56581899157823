import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { readCookie } from 'app/consumer/util/cookie'
import { environment } from 'environments/environment';

@Injectable()
export class ProfileService {

  private baseUrl = `${environment.apiConsumerBase}/current/profile/`;
  private contentType: string = 'application/vnd.com.rocketaccount.profile.v1+json';
  private accept: string = 'application/vnd.com.rocketaccount.profile.v1+json';

  private get headers(): HttpHeaders {
    return new HttpHeaders({
      'Accept': this.accept,
      'Content-Type': this.contentType,
      'Authorization': `Bearer ${readCookie('rocket-auth')}`
    });
  }

  constructor(private http: HttpClient) { }

  private clearEmptyValues(data: object): object | null {
    let keys = Object.keys(data);
    let cleanObj = {};

    for (let key of keys) {
      if (typeof data[key] == 'object' && data[key].length) {  // handle arrays
        let clean = data[key].map(elem => this.clearEmptyValues(elem)).filter(elem => elem);
        clean ? cleanObj[key] = clean : null;

      } else if (typeof data[key] == 'object') {  // handle objects
        let clean = this.clearEmptyValues(data[key]);
        clean ? cleanObj[key] = this.clearEmptyValues(data[key]) : null;

      } else if (key == 'preferredName') {
        cleanObj[key] = data[key];
      } else {  // anything else
        data[key] ? cleanObj[key] = data[key] : null;
      }
    }
    return Object.entries(cleanObj).length ? cleanObj : null;
  }

  getProfile() {
    return this.http.get(this.baseUrl, { headers: this.headers });
  }

  updateProfile(data) {
    let cleanData = this.clearEmptyValues(JSON.parse(data));
    if(Object.keys(cleanData).includes('dateOfBirth')) {
      let reverseDate = /(\d{2})[/-](\d{2})[/-](\d{4})/;
      let temp = cleanData['dateOfBirth'].replace(reverseDate, '$3-$1-$2');
      cleanData['dateOfBirth'] = temp;
    }
    return this.http.post(this.baseUrl, cleanData, { headers: this.headers });
  }

  lookup() {
    let url = `${environment.apiConsumerBase}/profile/lookup/`;
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${readCookie('rocket-auth')}`
    })
    return this.http.get(url, { headers: headers });
  }

}
