import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RedirectService } from 'app/services/generic/redirect.service';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { environment } from 'environments/environment';

@Injectable()
export class LoginService {

    constructor(
        public http: HttpClient,
        private redirectService: RedirectService) {
    }

    set redirect(url) {
        this.redirectService.redirect = url;
    }

    get redirect() {
        return this.redirectService.redirect;
    }

    login(username, password): Observable<any> {

        if ((username).indexOf('+') > -1) {
            username = (username).replace('+', '%2B');
        }

        password = this.encode(encodeURI(password));

        let body = `grant_type=password&username=${username}&password=${password}`;

        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + btoa(environment.federate.id + ':' + environment.federate.secret)
        });

        const options = { headers: headers };

        return this.http.post(environment.federateBase + 'as/token.oauth2', body, options).map(this.handleData);
    }

    logout() {
        localStorage.removeItem('token');
    }

    private handleData(res: Response) {
        let body = res;
        return body;
    }

    private encode(value: string): string {
        return value.replace(/;/g, '%3B')
             .replace(/,/g, '%2C')
             .replace(/\//g, '%2F')
             .replace(/\?/g, '%3F')
             .replace(/:/g, '%3A')
             .replace(/@/g, '%40')
             .replace(/&/g, '%26')
             .replace(/=/g, '%3D')
             .replace(/\+/g, '%2B')
             .replace(/\$/g, '%24')
             .replace(/-/g, '%2D')
             .replace(/_/g, '%5F')
             .replace(/\./g, '%2E')
             .replace(/!/g, '%21')
             .replace(/~/g, '%7E')
             .replace(/\*/g, '%2A')
             .replace(/'/g, '%27')
             .replace(/\(/g, '%28')
             .replace(/\)/g, '%29')
             .replace(/#/g, '%23')
    }
}
