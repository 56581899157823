import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/filter';
import { LoginService } from 'app/services/generic/login.service';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { MergeService } from 'app/consumer/merge/merge.service';
import { environment } from 'environments/environment';
import { writeCookie, deleteCookie } from 'app/consumer/util/cookie';

@Component({
    selector: 'app-login-form',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    providers: [LoginService]
})
export class SignInComponent implements OnInit {
    cookieOptions = {
        domain: environment.domain,
        path: '/'
    };

    status: boolean;
    token: string;
    error: any;
    attempts = 0;
    source: 'RM' | 'RH' | 'RHQ' | null;
    tempMessageDate = new Date('2019-03-04T00:00:00');
    showMergeModal: boolean = false;

    form = this.fb.group({
        username: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(128)]],
        password: ['', [Validators.required, Validators.maxLength(256)]]
    });

    alert: object | null = null;

    constructor(
        private _loginService: LoginService,
        private _activatedRoute: ActivatedRoute,
        private _analytics: AnalyticsService,
        private merge: MergeService,
        private router: Router,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        if (sessionStorage.getItem('returned')) {
            this.alert = { type: 'success', id: 'welcome' }
            window.sessionStorage.removeItem('returned');
        }

        if (this._activatedRoute.snapshot.queryParams['from'] === 'reset') {
            this.alert = { type: 'success', id: 'passwordReset' };
        }

        if (sessionStorage.getItem('invalid-session')) {
            this.alert = { type: 'error', id: 'inactivity' };
            sessionStorage.removeItem('invalid-session');
        }

        if (sessionStorage.getItem('email-verified')) {
            this.alert = { type: 'success', id: 'emailVerify' };
            sessionStorage.removeItem('email-verified');
        }

        if (sessionStorage.getItem('change-password')) {
            this.alert = { type: 'success', id: 'passwordChange' };
            sessionStorage.removeItem('change-password');
        }

        // we could flesh this out a bit better but right now it needs to be this way

        if (this._activatedRoute.snapshot.queryParams['redirect']) {
            this._loginService.redirect = this._activatedRoute.snapshot.queryParams['redirect'];
        } else if (sessionStorage.getItem('redirect')) {
            this._loginService.redirect = sessionStorage.getItem('redirect');
        } else {
            this._loginService.redirect = '/#/success-landing';
        }

        this.determineSource()

        if (this._activatedRoute.snapshot.queryParams['username']) {
            let username = decodeURIComponent(this._activatedRoute.snapshot.queryParams['username']);
            this.username.patchValue(username);
        }
    }

    onSubmit() {
        this.status = true;

        if (this.form.invalid) {
            this.username.markAsTouched();
            this.password.markAsTouched();
            return;
        }

        this._loginService.login(this.username.value, this.password.value).subscribe(
            (response) => {
                this.token = response.access_token;
                this._analytics.decodeToken(this.token);
                this._analytics.track('login_success');
                let options = Object.assign({}, this.cookieOptions, { expires: this.getCurrentTime() });
                writeCookie('rocket-auth', this.token, options);
                window.location.href = this._loginService.redirect;
            },
            (error) => {                              
                this.password.reset();
                this.error = error.error;
                const desc = this.error.error_description;
                this._analytics.track('login_error');

                // server messages
                const locked = 'Your account is locked. Please contact your system administrator.';
                const unverified = 'Email not verified.';
                const mismatch = 'We didn\'t recognize the username or password you entered. Please try again.';
                const disabled = 'Your account is disabled. Please contact your system administrator.';

                switch (desc) {
                    case locked:
                        this.alert = { type: 'error', id: 'clientLocked' }
                        setTimeout(() =>
                            this.router.navigateByUrl(`password-reset?username=${this.username.value}&locked=locked`),
                            5000
                        );
                        break;
                    case mismatch:
                        this.failedAttempt();
                        break;
                    case unverified:
                        this.router.navigateByUrl(`sign-in/verify?user=${this.username.value}`);
                        break;
                    case disabled:
                        this.alert = { type: 'error', id: 'clientDisabled' };
                        break;
                }
                this.status = false;
            }
        );
    }

    failedAttempt() {
        this.attempts++;
        this.alert = (this.attempts === 3 || this.attempts === 4)
            ? { type: 'error', id: 'clientWarning' }
            : { type: 'error', id: 'clientMistmatch' };
    }

    getCurrentTime() {
        const now = new Date();
        let time = now.getTime();
        time += 1320 * 1000;
        now.setTime(time);

        return now.toUTCString();
    }

    dismissAlert() {
        this.alert = null;
    }

    determineSource() {
        let s = this._loginService.redirect;
        if (s.includes('rocketmortgage.com')) this.source = 'RM';
        if (s.includes('rockethomes.com')) this.source = 'RH';
        if (s.includes('rockethq.com')) this.source = 'RHQ';
    }

    get isBeforeTempDate(): boolean {
        let now = new Date();
        return now < this.tempMessageDate;
    }

    get username() { return this.form.get('username') }
    get password() { return this.form.get('password') }
}
