import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.scss'],
  animations: [
    trigger('slideUp', [
      state('up', style({
        transform: 'translateY(0)',
        opacity: 1,
        height: 'auto'
      })),
      state('down', style({
        opacity: 0,
        height: 0
      })),
      transition('down => up', [style({
        transform: 'translateY(20%)',
        opacity: 0,
      }), animate(350)]),
      transition('up => down', animate('350ms 10ms'))
    ])
  ]
})
export class RequirementsComponent {

  @Input('passwordInitial') passwordInitial: string;
  @Input('toggleControl') isVisible: boolean;

  get capital(): boolean { return /[A-Z]/.test(this.passwordInitial) }
  get number(): boolean { return /\d/.test(this.passwordInitial) }
  get lower(): boolean { return /[a-z]/.test(this.passwordInitial) }
  get count(): boolean { return /(.{8,})/.test(this.passwordInitial) }
  get initial(): boolean { return !this.passwordInitial }
  get state(): string { return this.isVisible ? 'up': 'down'};

}
