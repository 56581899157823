import {
  AfterContentInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: 'rckt-option'
})
export class RcktOption {

  @Input() value;
  @Output() onSelect = new EventEmitter<object>();

  @HostListener('click', ['$event']) select(event) {
    this.onSelect.emit({
      value: this.value ? this.value : event.target.innerHTML,
      display: event.target.innerHTML
    });
  }

  element: ElementRef;

  constructor(el: ElementRef) {
    this.element = el;
  }
}

@Component({
  selector: 'rckt-select',
  templateUrl: './rckt-select.component.html',
  styleUrls: ['./rckt-select.component.scss']
})
export class RcktSelectComponent implements OnInit, AfterContentInit {

  @Input() label: string;
  @Input() control: FormControl;

  @ContentChildren(RcktOption) options: QueryList<RcktOption>
  @HostListener('document:click', ['$event']) clickOff(event) {
    if (this.isOpen && !this.el.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  isOpen: boolean = false;
  displayValue: string;

  constructor(private el: ElementRef) { }

  ngOnInit() { }

  ngAfterContentInit() {
    this.options.forEach(option => {
      option.onSelect.subscribe(data => this.setValue(data))
    });

    this.options.changes.subscribe( (change: QueryList<any>) => {
      change.forEach(option =>{
        option.onSelect.subscribe(data => this.setValue(data))
      })
    });

    if (this.control.value) {
      let selected = this.options.find(option => option.value == this.control.value);
      this.displayValue = selected.element.nativeElement.innerHTML;
    }
  }

  setValue(event) {
    this.displayValue = event.display;
    this.control.patchValue(event.value);
    this.isOpen = false;
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.control.markAsTouched();
  }

}
