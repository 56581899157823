class CookieOptions {
	path?: string;
	domain?: string;
	maxAge?: number;
	expires?: string;
}

export function readCookie(name: string): string | null {
	let cookies = document.cookie.split('; ');
	let cookie = cookies.find( elem => elem.includes(name + '='));
	return cookie ? cookie.split('=')[1] : null
}

export function writeCookie(key: string, value: string, options?: CookieOptions): void {
	let cookie = `${key}=${encodeURIComponent(value)}`;

	if (options.path) { cookie += `;path=${options.path}` }
	if (options.domain) { cookie += `;domain=${options.domain}` }
	if (options.maxAge) { cookie += `;max-age=${options.maxAge}` }
	if (options.expires) { cookie += `;expires=${options.expires}`}

	document.cookie = cookie;
}

export function deleteCookie(key: string, options?: CookieOptions ): void {
	let deleteOptions = Object.assign({}, options, { maxAge: -1 });
	writeCookie(key, '', deleteOptions);
}