import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConsumerModule } from './app/consumer/consumer.module';
import { environment } from './environments/environment';

declare let cookieMonster;

if (environment.production) {
    enableProdMode();
}

function initMonster() {
  let monster = (<any>window).cookieMonster();
  monster();
}

let cm = document.createElement('script');

let script = document.getElementsByTagName('script')[0];
script.parentNode.insertBefore(cm, script);

cm.type = 'text/javascript';
cm.async = true;
cm.onload = initMonster;
cm.src = environment.cookieMonster;

let body = document.getElementsByTagName('body')[0];
let reference = body.childNodes[0];
let lpScript = document.createElement('script');

body.insertBefore(lpScript, reference);
lpScript.type = 'text/javascript';
lpScript.src = `/assets/js/liveperson${ environment.production ? '' : '-staging' }.js`;

platformBrowserDynamic().bootstrapModule(ConsumerModule);

