import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UtilModule } from 'app/consumer/util/util.module';

// Routers
import { AppRoutingModule } from 'app/consumer/routers/app-routing.module';

// Components
import { ConsumerComponent } from 'app/consumer/components/consumer/consumer.component';
import { CreateAccountFormComponent } from 'app/consumer/components/create-account-form/create-account-form.component';
import { StubCreateAccountFormComponent } from 'app/consumer/components/stub-create-account-form/stub-create-account-form.component';
import { SignInComponent } from 'app/consumer/components/sign-in/sign-in.component';
import { PageNotFoundComponent } from 'app/consumer/components/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from 'app/consumer/components/reset-password/reset-password.component';
import { NewPasswordComponent } from 'app/consumer/components/new-password/new-password.component';
import { SuccessLandingComponent } from 'app/consumer/components/success-landing/success-landing.component';
import { EmailInstructionsComponent } from 'app/consumer/components/email-instructions/email-instructions.component';
import { RequirementsComponent } from 'app/consumer/components/requirements/requirements.component';
import { EmailVerifyComponent } from 'app/consumer/components/email-verify/email-verify.component';
import { SignedInVerificationComponent } from 'app/consumer/components/signed-in-verification/signed-in-verification.component';
import { ForgotUsernameComponent } from 'app/consumer/components/forgot-username/forgot-username.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangeUsernameComponent } from './components/change-username/change-username.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TalkToUsComponent } from './components/talk-to-us/talk-to-us.component';

// Directives
import { EqualValidator } from 'app/consumer/directives/equal-validator.directive';

// Services
import { RedirectService } from 'app/services/generic/redirect.service';
import { LoginService } from 'app/services/generic/login.service';
import { HttpService } from 'app/services/logical/create-account.service';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ProfileService } from 'app/services/profile.service';
import { AppHookService } from 'app/services/app-hook.service';
import { UserService } from 'app/services/user.service';
import { MetricsService } from 'app/services/metrics.service';
import { RcktSelectComponent, RcktOption } from './util/rckt-select/rckt-select.component';
import { LogoBlockComponent } from './components/logo-block/logo-block.component';
import { MergeService } from './merge/merge.service';
import { AnalyticsService } from 'app/services/generic/analytics.service';

// Spark
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'ra-universal' }),
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        UtilModule,
        AppRoutingModule,
        SparkAngularModule
    ],
    declarations: [
        AutofocusDirective,
        ChangePasswordComponent,
        ChangeUsernameComponent,
        ConsumerComponent,
        CreateAccountFormComponent,
        StubCreateAccountFormComponent,
        EmailInstructionsComponent,
        EmailVerifyComponent,
        EqualValidator,
        ForgotUsernameComponent,
        NewPasswordComponent,
        PageNotFoundComponent,
        ProfileComponent,
        RequirementsComponent,
        ResetPasswordComponent,
        SignedInVerificationComponent,
        SignInComponent,
        SuccessLandingComponent,
        TalkToUsComponent,
        RcktSelectComponent,
        RcktOption,
        LogoBlockComponent
    ],
    providers: [
        AnalyticsService,
        HttpService,
        LoginService,
        MergeService,
        MetricsService,
        ProfileService,
        RedirectService,
        AppHookService
    ],
    bootstrap: [ConsumerComponent]
})
export class ConsumerModule {
}
