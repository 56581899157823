import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProfileService } from 'app/services/profile.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';

@Injectable()
export class ProfileResolver implements Resolve<any> {

  constructor(private profileService: ProfileService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.profileService.getProfile().take(1).map(data => {
      if (data['status'] == 401) {
        this.router.navigate(['sign-in']);
      } else {
        return data;
      }
    })
  }
}
