import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './alert/alert.component';
import { MaskComponent } from './mask/mask.component';
import { ModalComponent } from './modal/modal.component';
import { ButtonComponent } from './button/button.component';
import { RcktInputComponent, RcktInputErrorComponent } from './rckt-input/rckt-input.component';
import { SingleColumnComponent } from './single-column/single-column.component';
import { TosComponent } from './tos/tos.component';
import { ProgressBarComponent, ProgressIndicatorComponent, ProgressStepComponent } from './progress-bar/progress-bar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    AlertComponent,
    ButtonComponent,
    MaskComponent,
    ModalComponent,
    ProgressBarComponent,
    ProgressIndicatorComponent,
    ProgressStepComponent,
    RcktInputComponent,
    RcktInputErrorComponent,
    SingleColumnComponent,
    TosComponent
  ],
  exports: [
    AlertComponent,
    ButtonComponent,
    MaskComponent,
    ModalComponent,
    ProgressBarComponent,
    ProgressIndicatorComponent,
    ProgressStepComponent,
    RcktInputComponent,
    RcktInputErrorComponent,
    SingleColumnComponent,
    TosComponent
  ]
})
export class UtilModule { }
