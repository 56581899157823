import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class ChangePasswordService {

  constructor(private _http: HttpClient) { }

  changePasswordSubmit(currentPassword, newPassword, bearerToken) {
    const headers = new HttpHeaders({
      'Content-Type': environment.headers.standard,
      'Accept': environment.headers.standard,
      'Authorization': `Bearer ${bearerToken}`
    });

    let request = {
      password: currentPassword,
      newPassword: newPassword
    };

    return this._http.post(environment.apiConsumerBase + '/current/account/password-change/request', JSON.stringify(request), {headers: headers})
  }

}
