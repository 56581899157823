import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SendEmailVerification } from 'app/services/logical/send-email-verification.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-signed-in-verification',
  templateUrl: './signed-in-verification.component.html',
  styleUrls: ['./signed-in-verification.component.scss'],
  providers: [SendEmailVerification]
})
export class SignedInVerificationComponent implements OnInit {
  email: string;
  sent: boolean;
  error: number;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _sendEmail: SendEmailVerification) { }

  ngOnInit() {
    if (this._activatedRoute.snapshot.queryParams['user']) {
      this.email = this._activatedRoute.snapshot.queryParams['user'];
    }

    this.sent = false;
  }

  sendEmailConfirmation() {
    this._sendEmail.verifyEmail(this.email, environment.apiConsumerBase)
      .subscribe(
        response => {
          this.sent = true;
        },
        error => {
          this.error = error.error[0].code;
        }
      );
  }

  dismissAlert() {
    this.error = null;
  }

}
