import { Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { matchOtherValidator, sessionChecker, checkBlacklist } from 'app/consumer/util/validators';
import { ChangeUsernameService } from 'app/services/logical/change-username.service';
import { AppHookService } from 'app/services/app-hook.service';
import { readCookie } from 'app/consumer/util/cookie';
import { regex } from 'app/consumer/util/regex';

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.scss'],
  providers: [ChangeUsernameService]
})
export class ChangeUsernameComponent implements OnInit {
  status: boolean;
  usernameModal: boolean = false;
  usernameCharacterCheck: boolean = false;
  oldUsernameCharacterCheck: boolean = false;
  token = readCookie('rocket-auth');
  usernameForm: FormGroup = this.fb.group({
    currentUsername: [''],
    newUsername: ['', [Validators.required, Validators.pattern(regex.email), Validators.minLength(5), matchOtherValidator('currentUsername', 'equal'), 
      checkBlacklist(), Validators.maxLength(128)]],
    confirmUsername: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(128), matchOtherValidator('newUsername', 'not')]]
  });
  errorCode: number;

  constructor(
    private fb: FormBuilder,
    private _location: Location,
    private _changeUsername: ChangeUsernameService,
    private route: ActivatedRoute,
    private hooks: AppHookService,
    private _router: Router) { }

  ngOnInit() {
    this.currentUsername.disable();
    if (!this.token) {
      this._router.navigateByUrl('sign-in');
    } else {
      this.currentUsername.patchValue(this.oldUsername())
    }
  }

  oldUsername() {
    const base64Url = this.token.split('.')[1];
    if (base64Url) {
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const obj = JSON.parse(window.atob(base64));

      return obj.rocketAccountUsername;
    } else {
      // bad token, redirect to sign in? alert message?
    }
  }

  handleError(event, field) {
    if (field === 'newUsername') {
      if (!(/^[a-zA-Z0-9$!*'`()+@_\-\.]*$/gi.test(event.target.value))) {
        this.usernameCharacterCheck = true;
      } else {
        this.usernameCharacterCheck = false;
      }
    } else {
      if (!(/^[a-zA-Z0-9$!*'`()+@_\-\.]*$/gi.test(event.target.value))) {
        this.oldUsernameCharacterCheck = true;
      } else {
        this.oldUsernameCharacterCheck = false;
      }
    }
  }

  openModal() {
    if (this.usernameForm.invalid) { 
        Object.keys(this.usernameForm.controls).forEach( control => {
            this.usernameForm.get(control).markAsTouched();
        })
        return; 
    }
    
    this.usernameModal = true;
  }

  closeModal() {
    this.usernameModal = false;
  }

  onSubmit() {
    this.usernameModal = false;
    this.status = true;
    this._changeUsername.changeUsernameSubmit(this.newUsername.value, this.token).subscribe(
      data => {
        this.status = false;
        if (this.hooks.isWebView) {
          this.hooks.messageApp(true, 'changeUsername', this.usernameForm.value);
        } else {
          const redirect = this.route.snapshot.queryParams['redirect'];
          this._router.navigateByUrl(`change-username/instructions?user=${this.newUsername.value}${redirect ? '&redirect=' + redirect : ''}`);
        }
      },
      error => {
        this.status = false;
        sessionChecker(error);
        for (const res of error.error) {
          this.errorCode = res.code;
        }
        this.hooks.messageApp(false, 'changeUsername', this.usernameForm.value, error);
      }
    );
  }

  goBack() {
    this._location.back();
  }

  dismissAlert() {
    this.errorCode = null;
  }

  get currentUsername() { return this.usernameForm.get('currentUsername') }
  get newUsername() { return this.usernameForm.get('newUsername') }
  get confirmUsername() { return this.usernameForm.get('confirmUsername') }

  get isWebView() {
    return this.hooks.isWebView;
  }
}
