import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SendEmailVerification } from '../../../services/logical/send-email-verification.service';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { UserService } from 'app/services/user.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-verify',
    templateUrl: './email-instructions.component.html',
    styleUrls: ['./email-instructions.component.scss'],
    providers: [SendEmailVerification, UserService]
})
export class EmailInstructionsComponent implements OnInit {

    email: string;
    redirect: string;
    sent: boolean;
    sentError: boolean;
    from: string;

    constructor(
        private router: Router,
        private _sendEmail: SendEmailVerification,
        private _activatedRoute: ActivatedRoute,
        private _analytics: AnalyticsService,
        private userService: UserService) {
    }

    ngOnInit() {

        // respecting scope bc pw reset uses session while create account passes user in the url param
        this.email = this._activatedRoute.snapshot.queryParams['user'] ?
            this._activatedRoute.snapshot.queryParams['user'] : sessionStorage.getItem('resetFor');

        if (this.router.url.includes('/password-reset')) {
            this.from = 'pass';

            if (sessionStorage.getItem('redirect') !== '/#/success-landing') {
                this.redirect = sessionStorage.getItem('redirect');
            }

        } else if (this.router.url.includes('/email')) {
            this.from = 'email';
            this._analytics.track('email_confirmation');
        }

        if (this.router.url.includes('/change-username')) {
            this.from = 'username';
        }
    }

    sendEmailConfirmation() {
        this._sendEmail.verifyEmail(this.email, environment.apiConsumerBase)
            .subscribe(
                () => this.sent = true,
                () => this.sentError = true
            );
    }

    sendPasswordConfirmation() {
        this.userService.requestPassword(this.email, this.redirect).subscribe(() => this.sent = true);
    }

    dismissAlert() {
       this.sent = false;
    }

    goToRedirect() {
        window.location.assign(this.redirect);
    }
}
