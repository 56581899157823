import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class SendEmailVerification {
    url: string;

    private options = {
        headers: new HttpHeaders({
            'Content-Type': environment.headers.standard,
            'Accept': environment.headers.standard
        })
    };

    constructor(private http: HttpClient,
                private _router: Router) {
    }

    verifyEmail(username: string, apiBase: string) {

        if (window.location.href.search('admin-panel') > -1) {
            this.url = `${apiBase}/account/email/verification/request/`;
        } else {
            this.url = `${apiBase}/account/email/verification/request/`;
        }

        const userObj = { username: username };

        return this.http.post(this.url, userObj, this.options);
    }

    updateUsernameVerify(id, username, token) {
        const url = `${environment.apiConsumerBase}/account/${id}/change-username/request`;
        const options = {
            headers: new HttpHeaders({
                'Content-Type': environment.headers.standard,
                'Accept': environment.headers.standard,
                'Authorization': `Bearer ${token}`
            })
        };
        const usernameObj = { username: username };

        return this.http.post(url, usernameObj, options);
    }
}
