export const environment = {
    production: false,
    envName: 'test',
    domain: '.test.rocketaccount.com',
    federateBase: 'https://authnodes.test.rocketaccount.com/',
    apiConsumerBase: 'https://test.rocketaccount.com/api',
    apiConsumerForward: '/signinsuccess',

    cookieMonster: 'https://test.cookiemonster.quickenloans.com/',

    partner: {
        rm: '/signinsuccess',
        rh: '/signinsuccess',
        rl: '/signinsuccess',
        rhq: '/signinsuccess'
    },

    federate: {
        id: 'rocket-account-web',
        secret: 'SdCErokP8tcI03CxWthRgJjRs5BA2mPGUmgsSwxPCxKfGfOFz7qOOYgzjFNLr2a8'
    },

    whitelist: [
        'qlcredit.com',
        'liferocket.com',
        'rockfin.com',
        'quickenloans.com',
        'rocketmortgage.com',
        'rockethomes.com',
        'rocket.dev',
        'rockethq.com',
        'everfi-next.net',
        'rockloans.com',
        'rocketloans.com',
        'fifoundry.net',
        'foc.zone'
    ],

    headers: {
        standard: 'application/json',
        version: 'application/vnd.com.rocketaccount.account.v1+json'
    }
};
