import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/services/user.service';
import { AppHookService } from 'app/services/app-hook.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    providers: [UserService]
})
export class ResetPasswordComponent implements OnInit {

    submitted: boolean;
    readonly: boolean;
    resetForm = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(128)])
    });

  alert: object | null;

  constructor(
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private hooks: AppHookService) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams['username']) {
      this.username.patchValue(this.route.snapshot.queryParams['username']);
      this.readonly = true;
    }
    if (this.route.snapshot.queryParams['locked']) {
      this.alert = { type: 'error', id: 'locked' }
    }
  }

  onSubmit() {
    if (this.resetForm.valid) {
      this.submitted = true;
      this.userService.requestPassword(this.username.value.trim())
        .subscribe(
          () => {
            if (this.hooks.isWebView) {
              this.hooks.messageApp(true, 'resetPassword', this.resetForm.value);
            } else {
              const redirect = this.route.snapshot.queryParams['redirect'];
              this.router.navigateByUrl(`password-reset/instructions?${redirect ? '&redirect=' + redirect : '' }`);
            }
          },
          error => {
            this.submitted = false;
            for (const res of error.error) {
              this.alert = { type: 'error', id: res.code };
            }
            this.hooks.messageApp(false, 'resetPassword', this.resetForm.value, error);
          }
        );
    } else {
      this.username.markAsTouched();
    }
  }

  dismissAlert() {
    this.alert = null;
  }

    get username() {
        return this.resetForm.get('username')
    }

    get isWebView() {
        return this.hooks.isWebView;
    }
}
