import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { readCookie } from 'app/consumer/util/cookie';
import { environment } from 'environments/environment';

@Injectable()
export class UserService {
    private url = `${environment.apiConsumerBase}/account`;
    private contentType: string = 'application/vnd.com.rocketaccount.account.v1+json';
    private accept: string = 'application/vnd.com.rocketaccount.account.v1+json';

    private get headers(): HttpHeaders {
        return new HttpHeaders({
            Accept: this.accept,
            'Content-Type': this.contentType,
            Authorization: `Bearer ${readCookie('rocket-auth')}`
        });
    }

    constructor(private http: HttpClient) {}

    requestPassword(username: string, redirect = ''): Observable<any> {
        sessionStorage.setItem('resetFor', username);

        this.contentType = 'application/json';
        this.accept = 'application/json';

        let url = `${this.url}/password-reset/request`;

        let body = {
            username: username
        };

        if (redirect) {
            body['redirect'] = redirect
        }

        return this.http.post(url, body, { headers: this.headers });
    }

    claimPassword(token, password): Observable<any> {
        sessionStorage.removeItem('resetFor');

        this.contentType = 'application/json';
        this.accept = 'application/json';

        let url = `${this.url}/password-reset/claim`;
        let body = {
            token: token,
            password: password
        };

        return this.http.post(url, body, { headers: this.headers });
    }
}
