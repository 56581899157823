import { Component, OnInit } from '@angular/core';
import { HttpService } from 'app/services/logical/create-account.service';
import { AppHookService } from 'app/services/app-hook.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { LoginService } from 'app/services/generic/login.service';
import { environment } from 'environments/environment';
import { oneName, matchOtherValidator } from 'app/consumer/util/validators';
import { writeCookie } from 'app/consumer/util/cookie';
import { regex } from 'app/consumer/util/regex';

@Component({
    selector: 'app-stub-create-account-form',
    templateUrl: './stub-create-account-form.component.html',
    styleUrls: ['./stub-create-account-form.component.css'],
    providers: [HttpService]
})
export class StubCreateAccountFormComponent implements OnInit {

    presets: any = {
        firstName: '',
        lastName: '',
        username: ''
    }; // holder for pre-populated form values

    cookieOptions = {
        domain: environment.domain,
        path: '/'
    };

    postData: any;
    status: boolean;
    invalid: boolean;
    token: string;
    emailCharacterCheck: boolean;
    mobileOS: string;

    form = this.fb.group({
        firstName: ['', [Validators.minLength(2), Validators.required, oneName(), Validators.pattern(/^[a-zA-Z`'\-. ]*$/)]],
        lastName: ['', [Validators.minLength(2), Validators.required, oneName(), Validators.pattern(/^[a-zA-Z`'\-. ]*$/)]],
        username: ['', [Validators.required,  Validators.pattern(regex.email), Validators.maxLength(128)]]
    });

    errorCode: number;

    constructor(
        private _httpService: HttpService,
        private _router: Router,
        private route: ActivatedRoute,
        private _analytics: AnalyticsService,
        private hooks: AppHookService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams['ragn']) {
            this.presets['firstName'] = atob(this.route.snapshot.queryParams['ragn']);
        }

        if (this.route.snapshot.queryParams['rasn']) {
            this.presets['lastName'] = atob(this.route.snapshot.queryParams['rasn']);
        }

        if (this.route.snapshot.queryParams['race']) {
            this.presets['username'] = atob(this.route.snapshot.queryParams['race']);
        }

        this.form.patchValue(this.presets);

        this.mobileOS = this.route.snapshot.queryParams['app'];
    }

    onSubmit() {
        this.status = true;
        let payload = Object.assign({}, this.form.value);

        this._httpService.postCreateStub(this.form.value).subscribe(
            data => {
                this.postData = data;
                this.hooks.messageApp(true, 'createAccount', payload);
            },
            error => {
                for (const res of error.error) {
                    this.errorCode = res.code;
                }

                this.status = false;
                this.hooks.messageApp(false, 'stubCreateAccount', payload, error.error[0]);
            },
            () => {
                let options = Object.assign({}, this.cookieOptions, { expires: this.getCurrentTime() });
                writeCookie('rocket-auth', this.postData.access_token, options);
                this._analytics.storeId(this.parseJwtRaid(this.postData.access_token));
                this._analytics.track('stubaccountcreated');

                this.route.snapshot.queryParams['redirect']
                    ? (window.location.href = this.route.snapshot.queryParams['redirect'])
                    : this._router.navigateByUrl('success-landing');
            }
        );
    }

    handleCharacters(event) {
        if (!/^[a-zA-Z0-9$!*'`()+@_\-\.]*$/gi.test(event.target.value)) {
            this.emailCharacterCheck = true;
        } else {
            this.emailCharacterCheck = false;
        }
    }

    getCurrentTime() {
        const now = new Date();
        let time = now.getTime();
        time += 1320 * 1000;
        now.setTime(time);

        return now.toUTCString();
    }

    trimWhitespace(attr) {
        if (this.form.get(attr)) {
            this.form.get(attr).setValue(this.form.get(attr).value.trim());
        }
    }

    dismissAlert() {
        this.errorCode = null;
    }

    get firstName() {
        return this.form.get('firstName');
    }

    get lastName() {
        return this.form.get('lastName');
    }

    get username() {
        return this.form.get('username');
    }

    get isWebView() {
        return this.hooks.isWebView;
    }

    // todo get rid of this after ab test for RM slated for 9/24 as it won't be relevant anymore

    parseJwtRaid(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let raid = JSON.parse(atob(base64));

        return raid.rocketAccountId;
    }
}
