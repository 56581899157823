import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'single-column',
  templateUrl: './single-column.component.html',
  styleUrls: ['./single-column.component.scss']
})
export class SingleColumnComponent implements OnInit {

  @Input() terms: boolean = true;
  @Input() talk: boolean = true;

  displayTOS: boolean = false;

  constructor() { }

  ngOnInit() { }

  toggleTOS() {
    this.displayTOS = !this.displayTOS;
  }

}
