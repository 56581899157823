import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class ChangeUsernameService {

  constructor(private _http:HttpClient) { }

  changeUsernameSubmit(newUserName, bearerToken) {
    const headers = new HttpHeaders({
      'Content-Type' : environment.headers.standard,
      'Accept': environment.headers.standard,
      'Authorization': `Bearer ${bearerToken}`
    });

    const request = {
      username: newUserName
    };

    return this._http.post(environment.apiConsumerBase + '/current/account/change-username/request', JSON.stringify(request), {headers: headers});
  }

}
