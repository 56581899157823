import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { matchOtherValidator } from 'app/consumer/util/validators';
import { regex } from 'app/consumer/util/regex';
import { LoginService } from 'app/services/generic/login.service';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.css'],
    providers: [UserService]
})
export class NewPasswordComponent implements OnInit {

    token: string;
    submitted: boolean;
    username: string = sessionStorage.getItem('resetFor');
    type = 'password';
    confirmType = 'password';
    passwordFocused: boolean = true;

    newPasswordForm = new FormGroup({
        password: new FormControl('', [Validators.required, Validators.pattern(regex.password)]),
        confirm: new FormControl('', [Validators.required, matchOtherValidator('password', 'not')])
    });

    errorCode: number;

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private router: Router,
        private loginService: LoginService
    ) { }

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');

        if (this.route.snapshot.queryParams['redirect']) {
            this.loginService.redirect = atob(this.route.snapshot.queryParams['redirect']);
        }
    }

    onSubmit() {
        if (this.newPasswordForm.invalid) {
            Object.values(this.newPasswordForm.controls).forEach(control => control.markAsTouched());
            return;
        }

        this.submitted = true;
        this.userService.claimPassword(this.token, this.password.value)
            .subscribe(
                data => {
                    sessionStorage.removeItem('resetFor');
                    this.router.navigateByUrl('sign-in?from=reset');
                },
                error => {
                    this.submitted = false;
                    for (let res of error.error) {
                        this.errorCode = res.code;
                    }
                }
            );
    }

    dismissAlert() {
        this.errorCode = null;
    }

    get password() {
        return this.newPasswordForm.get('password');
    }

    get confirm() {
        return this.newPasswordForm.get('confirm');
    }
}
