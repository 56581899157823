import { Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { deleteCookie, readCookie } from 'app/consumer/util/cookie';
import { environment } from 'environments/environment';

@Injectable()
export class KillSessionCheck implements Resolve<any> {

    constructor(private analytics: AnalyticsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let options = {
            domain: environment.domain
        };

        const cookieName = 'rocket-auth';
        let url = `https://${ environment.production ? '' : environment.envName + '.' }rocketaccount.com/#` + state.url;

        if (readCookie(cookieName)) {
            deleteCookie(cookieName, options);
            window.location.replace(`${environment.federateBase}idp/startSLO.ping?TargetResource=` + encodeURIComponent(url));
            this.analytics.track('forced-sign-out');
        }

        return true;
    }
}
