import { Injectable } from '@angular/core';
import { RedirectService } from 'app/services/generic/redirect.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class JamesService {

	private url = `${environment.apiConsumerBase}/account/james1/`;
	private options = { headers: new HttpHeaders({
		'Content-Type': environment.headers.version,
        'Accept': environment.headers.version
	})};

    constructor(
        private _redirectService: RedirectService,
        private http: HttpClient) { }

    postCreateJames(newUser) {
        return this.http.post(this.url, JSON.stringify(newUser), this.options);
    }
}
